import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService, User } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { from, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { LoginConstants } from "../models/constants/loginConstants";
import { AuthZTypes } from "../models/login/AuthZTypes";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { ToasterService } from "../services/toaster.service";
import { loadUserData } from "../store/cache.action";
import { UserState } from "../store/cache.reducer";

/**
 * Check whether the user is authenticated using SiemensID and authorized using AuthZ.
 * Redirects if not authenticated or authorized.
 */
@Injectable({
  providedIn: "root",
})
export class IsAuthorized implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private toasterService: ToasterService,
    private store: Store<{ userState: UserState[] }>
  ) {}

  errorRedirectUrl = this.router.parseUrl("/notregistered");
  loginRedirectUrl = this.router.parseUrl("/login");
  registerUrl = this.router.parseUrl("/register");

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GuardResult> {
    this.loginRedirectUrl.queryParams = { returnUrl: state.url };

    return this.auth.user$.pipe(
      mergeMap((user) => {
        /**
         * User is authenticated with SiemensID.
         * Checks if the user is authorized with AuthZ
         */
        if (user) {
          return from(
            serviceFactory.AuthService.checkUserRegistration(
              user[LoginConstants.GID]
            )
          ).pipe(
            map((registrationStatus) =>
              this.checkUserRegStatus(registrationStatus, state)
            ),
            catchError((error) => {
              return of(
                new RedirectCommand(this.errorRedirectUrl, {
                  skipLocationChange: true,
                })
              );
            })
          );
        } else {
          return of(
            new RedirectCommand(this.loginRedirectUrl, {
              skipLocationChange: true,
            })
          );
        }
      })
    );
  }

  checkUserRegStatus(
    status: AuthZTypes,
    state: RouterStateSnapshot
  ): GuardResult {
    this.loginRedirectUrl.queryParams = {
      returnUrl: state.url,
      isNotAuthenticated: true,
    };
    //FIXME:: To handle for potential registration status
    switch (status) {
      //FIXME: Add tests for this behaviour
      //FIXME: To handle this in SST-983
      case AuthZTypes.NOT_REGISTERED: {
        return new RedirectCommand(this.registerUrl, {
          skipLocationChange: false,
        });
      }
      //FIXME: To handle this in SST-983
      case AuthZTypes.ACCOUNT_ACTIVATED: {
        this.toasterService.showInfo(
          this.toasterService.toasterMessage(
            "toaster-message.activationSuccessful"
          )
        );
        return true;
      }
      case AuthZTypes.ALREADY_REGISTERED: {
        return true;
      }
      //FIXME: To handle this in SST-983
      case AuthZTypes.INACTIVE_USER: {
        this.toasterService.showInfo(
          this.toasterService.toasterMessage("toaster-message.inactiveUser")
        );
        return new RedirectCommand(this.loginRedirectUrl, {
          skipLocationChange: true,
        });
      }
    }
  }

  dispatchUserDetails(user: User) {
    this.store.dispatch(
      loadUserData({
        key: LoginConstants.FIRSTNAME,
        value: user.given_name,
      })
    );
    this.store.dispatch(
      loadUserData({
        key: LoginConstants.LASTNAME,
        value: user.family_name,
      })
    );
    this.store.dispatch(
      loadUserData({
        key: LoginConstants.EMAILID,
        value: user.email,
      })
    );
  }
}

/**
 * Check whether the user is authenticated using SiemensID.
 * Redirects if not authenticated.
 */
@Injectable({
  providedIn: "root",
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GuardResult> {
    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) return true;
        else
          return new RedirectCommand(this.router.parseUrl("/login"), {
            skipLocationChange: true,
          });
      })
    );
  }
}
