import DriveProjectAttribute from "src/app/models/drive/driveProjectAttribute";
import { Constants } from "../../../app/models/constants/Constants";
import ProjectInfo from "../../../app/models/drive/projectInfo";
import AxiosClient from "../client/AxiosClient";
import { DriveProjectAttributeNames } from "../drive/constants/DriveProjectAttributeNames";
import { ResultType } from "../models/Result";
import serviceUrlConstants from "../serviceLayer/serviceUrlConstants";
import DriveFileResponse from "./DriveFileResponse";

export default class FileService {
  async uploadFile(file: File, projectId: string): Promise<ProjectInfo> {
    const url = serviceUrlConstants.fileImportUrl(projectId);
    let projectInfo: ProjectInfo = null;
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    let header = { headers: AxiosClient.GetHeader("multipart/form-data") };
    await AxiosClient.post(url, header, bodyFormData)
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            projectInfo = response.value;
            projectInfo = this.checkEmptyAttributes(projectInfo);
            return projectInfo;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
        throw error;
      });
    return projectInfo;
  }
  checkEmptyAttributes(projectInfo: ProjectInfo): ProjectInfo {
    projectInfo.client = !projectInfo.client
      ? this.setDefaultAttribute(DriveProjectAttributeNames.CLIENT)
      : projectInfo.client;
    projectInfo.country = !projectInfo.country
      ? this.setDefaultAttribute(DriveProjectAttributeNames.COUNTRY)
      : projectInfo.country;
    projectInfo.designOffice = !projectInfo.designOffice
      ? this.setDefaultAttribute(DriveProjectAttributeNames.DESIGN_OFFICE)
      : projectInfo.designOffice;
    projectInfo.language = !projectInfo.language
      ? this.setDefaultAttribute(DriveProjectAttributeNames.LANGUAGE)
      : projectInfo.language;
    projectInfo.location = !projectInfo.location
      ? this.setDefaultAttribute(DriveProjectAttributeNames.LOCATION)
      : projectInfo.location;
    projectInfo.projectDescription = !projectInfo.projectDescription
      ? this.setDefaultAttribute(DriveProjectAttributeNames.PROJECT_DESCRIPTION)
      : projectInfo.projectDescription;
    projectInfo.comment = !projectInfo.comment
      ? this.setDefaultAttribute(DriveProjectAttributeNames.COMMENT)
      : projectInfo.comment;
    return projectInfo;
  }
  setDefaultAttribute(attribute: string): DriveProjectAttribute {
    let projectAttribute: DriveProjectAttribute;
    projectAttribute.name = attribute;
    projectAttribute.value = "";

    return projectAttribute;
  }
  async uploadFileToDrive(projectId: string): Promise<DriveFileResponse> {
    let driveFileResponse = new DriveFileResponse();
    let header = { headers: AxiosClient.GetHeader("application/json") };
    const url = serviceUrlConstants.serverFileImportUrl(projectId);

    await AxiosClient.post(url, header, "")
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            driveFileResponse = response.value;
            return driveFileResponse;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
        throw error;
      });
    return driveFileResponse;
  }
  async importFile(url: string): Promise<File> {
    let file: File = null;
    let headerConfig = {};
    await AxiosClient.get(
      url,
      {
        headers: headerConfig,
        responseType: "blob",
      },
      "blob"
    )
      .then((response) => {
        // window.open(url);
        console.log(response, "response");
        //TODO: convert arraybuffer to File
        switch (response.type) {
          case ResultType.SUCCESS: {
            // let blobObj=new Blob(response.value);
            // file = new File(blobObj, "configuration.sc");
            const blob = new Blob([response.value], {
              type: "application/octet-stream",
            });
            let myFile = this.blobToFile(blob, "configuration.sc");
            console.log(myFile.type, "file");
            file = myFile;
            return file;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((e) => {
        console.log(e, "error");
        // throw e;
      });
    return file;
  }
  blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob as Blob], fileName);
  }
}
