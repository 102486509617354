import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { removeSessionCookie } from "../../lib/utils";
import { environment } from "../../../src/environments/environment";
import SpinnerModel from "../models/common/SpinnerModel";
import { Constants } from "../models/constants/Constants";
import { EventType } from "../models/constants/eventType";
import { ProjectService } from "../projects/services/project.service";
import { serviceFactory } from "./serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "./serviceLayer/session-expired-dialog.service";
import { ToasterService } from "./toaster.service";

@Injectable()
export class SessionTimerService {
  _timeoutSeconds: number;
  timerSubscription;
  startTime = new Date();
  bufferTime: number = 60;
  sessionTimeOut: number = environment.sessionTime;
  constructor(
    private eventBus: NgEventBus,
    private sessionExpiredDialog: SessionExpiredService,
    private router: Router,
    private projectService: ProjectService,
    private toasterService: ToasterService,
  ) {}

  startTimer() {
    this.stopTimer();
    this.timerSubscription = setInterval((_) => {
      let currentTime = new Date();
      let idleTime = +currentTime - +this.startTime;
      this._timeoutSeconds = this.sessionTimeOut - Math.floor(idleTime / 1000);
      if (this._timeoutSeconds === 0 || this._timeoutSeconds < 0) {
        this._timeoutSeconds = 0;
        this.stopTimer();
        this.sessionExpiredDialog.openDialog();
        this.eventBus.cast(EventType.SESSIONTIMEDOUT, true);
      } else if (
        this._timeoutSeconds === this.bufferTime &&
        this.router.url.includes("/projects/")
      ) {
        this.saveProjectConfiguration();
      }
    }, 1000);
  }
  async saveProjectConfiguration() {
    let spinnerModel = new SpinnerModel();

    spinnerModel.show = true;
    spinnerModel.text = "loader.saveConfiguration";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, spinnerModel);

    await serviceFactory.ProjectService.saveConfiguration(
      this.projectService.projectDetails().id
    )
      .then(async (fileResponse) => {
        removeSessionCookie("JSESSIONID");
      })
      .catch((error: AxiosError) => {
        if (error.response?.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error?.message);
        }
      })
      .finally(async () => {
        spinnerModel.show = false;
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, spinnerModel);
      });
  }
  stopTimer() {
    if (this.timerSubscription) {
      clearInterval(this.timerSubscription);
    }
  }

  resetTimer() {
    this.startTime = new Date();
  }
}
