<div
  class="d-flex h-100 flex-column justify-content-around overflow-hidden p-4"
>
  <div class="d-flex justify-content-between">
    <h3>{{ "CurrentTimeDialog_title" | translate }}</h3>
    <button ui-button size="icon-small" variant="tertiary" (click)="close()">
      <app-close-icon />
    </button>
  </div>
  <div>
    <div class="flex gap-3">
      <label
        class="flex-1"
        [ngClass]="
          data.isMarkOnSeriesData ? 'text-width-label' : 'text-width-noncurve'
        "
        >{{ xLabel }}</label
      >
      <label
        class="flex-1"
        [ngClass]="
          data.isMarkOnSeriesData ? 'text-width-label' : 'text-width-noncurve'
        "
        >{{ yLabel }}</label
      >
    </div>
    <div class="flex items-center gap-3">
      <div
        [ngClass]="data.isMarkOnSeriesData ? 'text-width' : 'w-50'"
        appTooltip
        tooltip="{{ tooltipXVal }}"
        placement="top"
      >
        <input
          id="current"
          class="w-100"
          data-testid="currentValue"
          type="text"
          ui-input
          [(ngModel)]="data.translatedCurrentValue"
          (input)="updatedTime($event.target.value)"
        />
      </div>
      <div
        [ngClass]="data.isMarkOnSeriesData ? 'text-width' : 'w-50'"
        appTooltip
        tooltip="{{ tooltipYVal }}"
        placement="top"
      >
        <input
          id="time"
          class="w-100"
          data-testid="timeValue"
          type="text"
          ui-input
          [(ngModel)]="data.translatedTimeValue"
          (input)="updateCurrent($event.target.value)"
        />
      </div>
      <button
        ui-button
        *ngIf="data.isMarkOnSeriesData && isShowLinkUnlink"
        size="icon-small"
        variant="tertiary"
        (click)="linkChange()"
      >
        <app-link-icon [type]="'link'"></app-link-icon>
      </button>
      <button
        ui-button
        *ngIf="data.isMarkOnSeriesData && !isShowLinkUnlink"
        size="icon-small"
        variant="tertiary"
        (click)="linkChange()"
      >
        <app-link-icon [type]="'unlink'"></app-link-icon>
      </button>
    </div>
  </div>
  <div class="d-flex flex-row-reverse gap-3">
    <button
      ui-button
      data-testid="addMarkOk"
      type="submit"
      (click)="proceed()"
      [disabled]="!isValidXYValue()"
    >
      {{ "Button_add" | translate }}
    </button>
    <button ui-button variant="secondary" (click)="cancelEvent()">
      {{ "CANCEL" | translate }}
    </button>
  </div>
</div>
