import { AxiosError } from "axios";
import { CatalogAttributeModel } from "../../models/catalog/CatalogAttributesModel";
import { Constants } from "../../../app/models/constants/Constants";
import FacadeListEntryModel from "../../models/devices/FacadeListEntry";
import ListEntry from "../../models/devices/ListEntry";
import OrderNumber from "../../models/devices/orderNumber";
import Product from "../../models/devices/product";
import ProductGroupsModel from "../../models/devices/ProductGroupsModel";
import ProtectiveDevice from "../../models/devices/protectiveDevice";
import ListEntryMarkModel from "../../models/marks/GetListEntryModel";
import MarkModel from "../../models/marks/MarkModel";
import AxiosClient from "../client/AxiosClient";
import { ResultType } from "../models/Result";
import ColorUtil from "./helper/ColorUtil";
import serviceUrlConstants from "./serviceUrlConstants";
export default class ProductService {
  async getProductGroups(pageNo = 1): Promise<any> {
    let productGroups: ProductGroupsModel[] = [];
    let url = serviceUrlConstants.getProductGroupsUrl(pageNo, 25);
    await AxiosClient.getWithRequestHeaders(url, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
      .then(async (response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            let currentPage = parseInt(response.value.headers["x-page"]);
            let totalPage = parseInt(response.value.headers["x-total-pages"]);
            productGroups = response.value.data;
            while (currentPage !== totalPage) {
              url = serviceUrlConstants.getProductGroupsUrl(
                currentPage + 1,
                25
              );
              await AxiosClient.getWithRequestHeaders(url, {
                headers: { "Access-Control-Allow-Origin": "*" },
              }).then((value) => {
                switch (value.type) {
                  case ResultType.SUCCESS: {
                    currentPage = parseInt(value.value.headers["x-page"]);
                    productGroups = Object.assign(
                      value.value.data,
                      productGroups
                    );
                  }
                }
              });
            }

            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return productGroups;
  }
  mapOrderNumberToBaseModel(
    response: string[],
    productGroup: string
  ): OrderNumber[] {
    let orderNumberList = new Array<OrderNumber>();
    response.forEach((orderNumber) => {
      let orderNumberBaseModel = new OrderNumber();
      orderNumberBaseModel.name = orderNumber;
      orderNumberBaseModel.type = Constants.ORDER_NUMBER;
      orderNumberBaseModel.productGroup = productGroup;
      orderNumberList.push(orderNumberBaseModel);
    });
    return orderNumberList;
  }
  async getOrderNumbersFromProductGroups(
    productGroup: string
  ): Promise<OrderNumber[]> {
    if (productGroup) {
      let orderNumbers: OrderNumber[] = [];
      productGroup = productGroup.toUpperCase();
      let url = serviceUrlConstants.getOrderNumbersUrl(productGroup);
      await AxiosClient.get(url, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {
          switch (response.type) {
            case ResultType.SUCCESS: {
              let orderNumbersArray = this.mapOrderNumberToBaseModel(
                response.value,
                productGroup
              );
              break;
            }
            case ResultType.FAIL:
              throw response.message;
          }
          return orderNumbers;
        })
        .catch((error) => {
          throw error;
        });
      return orderNumbers;
    } else {
      let error = new AxiosError(Constants.INVALID_PRODUCTGROUP_ERROR);
      throw error;
    }
  }

  async getProductAttributesFromOrderNumber(
    orderNumber: string,
    nominalVoltage: string
  ): Promise<Product> {
    let product: Product = new Product();
    if (orderNumber && nominalVoltage) {
      let url = serviceUrlConstants.getProductAttributesUrl(
        orderNumber,
        nominalVoltage
      );
      await AxiosClient.get(url, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {
          switch (response.type) {
            case ResultType.SUCCESS: {
              product = this.mapProductAttributesToModel(response.value);
              break;
            }
            case ResultType.FAIL:
              throw response.message;
          }
          return product;
        })
        .catch((error) => {
          throw error;
        });
      return product;
    }
  }
  //TODO::Folder Id Removal Migration
  async getListEntry(
    projectId: string,
    orderNumber?: string,
    voltage?: number,
    ambientTemparature?: number,
    itemType?: string,
    isFromCatalog?: boolean
  ): Promise<ListEntry> {
    let listEntry = new ListEntry();
    let color = ColorUtil.getDarkColor();
    let lineStyle = "Solid";
    let lineWidth = "2";
    let url = await serviceUrlConstants.getProjectUrl(
      projectId,
      orderNumber,
      voltage,
      ambientTemparature,
      itemType,
      isFromCatalog
    );
    // if (orderNumber) {
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage.i18nextLng,
        },
        timeout: 30000,
      },
      {
        hexColorCode: color,
        lineStyle: lineStyle,
        lineWidth: lineWidth,
      }
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            listEntry = response.value;
            listEntry.mProduct = this.mapProductAttributesToModel(
              listEntry.mProduct
            );
            listEntry.color = convertRgbToHex(listEntry.curveStyle["rgb"]);
            let style, mCap;
            if (listEntry.curveStyle["mLineAttributesWrapper"] != null) {
              listEntry.lineWidth =
                listEntry.curveStyle["mLineAttributesWrapper"].mLineWidth;
              // listEntry.lineStyle =
              style = listEntry.curveStyle["mLineAttributesWrapper"].mStyle;
              mCap = listEntry.curveStyle["mLineAttributesWrapper"].mCap;
            } else {
              listEntry.lineWidth =
                listEntry.curveStyle["lineAttributes"].width;
              // listEntry.lineStyle =
              style = listEntry.curveStyle["lineAttributes"].style;
            }
            listEntry.lineStyle = this.mapCodeToLineStyle(style, mCap);
            return listEntry;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return listEntry;
    // } else {
    //   let error = new AxiosError(Constants.INVALID_PRODUCTGROUP_ERROR);
    //   throw error;
    // }
  }
  mapCodeToLineStyle(mStyle: number, mCap: Number) {
    let lineStyle;
    switch (mStyle) {
      case 1:
        lineStyle = "Solid";
        break;
      case 2:
        switch (mCap) {
          case 1:
            lineStyle = "LongDash";
            break;
          case 2:
            lineStyle = "LongRound";
        }
        break;
      case 3:
        switch (mCap) {
          case 1:
            lineStyle = "ShortDash";
            break;
          case 2:
            lineStyle = "ShortRound";
        }
        break;
      case 4:
        switch (mCap) {
          case 1:
            lineStyle = "LongDashDot";
            break;
          case 2:
            lineStyle = "LongRoundDot";
        }
        break;
      case 5:
        switch (mCap) {
          case 1:
            lineStyle = "LongDashDotDot";
            break;
          case 2:
            lineStyle = "LongRoundDotDot";
        }
        break;
      case 6:
        lineStyle = "CustomRoundDot";
        break;
    }
    return lineStyle;
  }
  mapProductAttributesToModel(response: any): Product {
    let productAttribute = new Product();
    if (response) {
      productAttribute.attachments = response.m_Attachments;
      productAttribute.attributes = response.m_Attributes;
      productAttribute.mlfb = response.m_strMlfb;
      productAttribute.productgroup = response.m_strProductgroup;
      productAttribute.productgroupID = response.m_strProductgroupID;
      productAttribute.textLanguage = response.m_strTextLanguage;
      productAttribute.quantity = response.m_iQuantity;
    }

    return productAttribute;
  }
  async getNominalVoltage(): Promise<number[]> {
    let nominalVoltage = Array<number>();
    let url = serviceUrlConstants.getNominalVoltage();
    await AxiosClient.get(url, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            nominalVoltage = response.value;
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return nominalVoltage;
      })
      .catch((error) => {
        throw error;
      });
    return nominalVoltage;
  }
  async getNominalVoltageForMlfb(mlfb: string): Promise<CatalogAttributeModel> {
    let nominalVoltage = new CatalogAttributeModel();
    let url = serviceUrlConstants.getNominalVoltageForMlfbUrl(mlfb);
    await AxiosClient.get(url, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            nominalVoltage = response.value;
            break;
          }
          case ResultType.FAIL:
            throw response.message;
        }
        return nominalVoltage;
      })
      .catch((error) => {
        throw error;
      });
    return nominalVoltage;
  }
  //TODO::Folder Id Removal Migration
  async getImportedDevices(projectId: string): Promise<ListEntry[]> {
    // let listEntries = new Array<ListEntry>();
    // let markenteries = new Array<MarkModel>();
    // let getListEntryModels = new GetListEntryModel();
    // let getListEntryModelsupdated = new GetListEntryModel();
    let listEntryResponse = new Array<ListEntry>();
    let url = serviceUrlConstants.getImportedDevices(projectId);

    await AxiosClient.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      timeout: 30000,
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            // getListEntryModels = response.value;
            // markenteries = getListEntryModels[0];
            let listEntries = response.value;
            // for (let i = 0; i < markenteries.length; i++) {
            //   let mark = markenteries[i];
            //   mark.rgbValue = convertRgbToHex(mark.rgbValue);
            //   markenteries[i].mlabel = mark.rgbValue;
            // }
            for (let index = 0; index < listEntries.length; index++) {
              let listEntry = listEntries[index];
              if (listEntry.curveStyle) {
                listEntry.color = convertRgbToHex(listEntry.curveStyle["rgb"]);
                listEntry.mProduct = this.mapProductAttributesToModel(
                  listEntry.mProduct
                );
                let style, mCap;
                if (listEntry.curveStyle["mLineAttributesWrapper"] != null) {
                  listEntry.lineWidth =
                    listEntry.curveStyle["mLineAttributesWrapper"].mLineWidth;
                  style = listEntry.curveStyle["mLineAttributesWrapper"].mStyle;
                  mCap = listEntry.curveStyle["mLineAttributesWrapper"].mCap;
                } else {
                  listEntry.lineWidth =
                    listEntry.curveStyle["lineAttributes"].width;
                  style = listEntry.curveStyle["lineAttributes"].style;
                }
                listEntry.lineStyle = this.mapCodeToLineStyle(style, mCap);
              }
              listEntryResponse.push(listEntry);
            }
            // getListEntryModelsupdated.listEntry = listEntryResponse;
            // getListEntryModelsupdated.markModel = markenteries;

            return listEntryResponse;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return listEntryResponse;
  }
  //TODO::Folder Id Removal Migration
  async synchronizeDeviceData(
    listEntry: ListEntry,
    facadeListEntry: FacadeListEntryModel,
    protectiveDevice: ProtectiveDevice,
    projectId: string
  ) {
    let url = serviceUrlConstants.synchronizeDeviceUrl(
      projectId,
      facadeListEntry.listEntry.index
    );

    let responseBody = this.postBodyForSynchronizeDevice(
      listEntry,
      facadeListEntry.listEntry.mLabel,
      facadeListEntry.listEntry.index,
      protectiveDevice.color,
      protectiveDevice.lineWidth,
      protectiveDevice.lineStyle,
      facadeListEntry.listEntry.visible
    );
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage.i18nextLng,
        },
        timeout: 30000,
      },
      responseBody
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            // listEntry = response.value;
            listEntry = this.mapCurveStyle(response.value);
            return listEntry;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return listEntry;
  }
  postBodyForSynchronizeDevice(
    listEntry: ListEntry,
    label: string,
    index: number,
    lineColor: string,
    lineWidth: number,
    lineStyle: string,
    visible: boolean
  ) {
    let body = {
      mProduct: listEntry.mProduct,
      mProtectiveDevice: listEntry.mProtectiveDevice,
      label: label,
      index: index,
      visible: visible,
      lineConfigModel: {
        hexColorCode: lineColor,
        lineStyle: lineStyle,
        lineWidth: lineWidth,
      },
    };

    return body;
  }
  mapCurveStyle(listEntry: ListEntry) {
    let style, cap;
    listEntry.color = convertRgbToHex(listEntry.curveStyle["rgb"]);

    if (listEntry.curveStyle["mLineAttributesWrapper"] != null) {
      listEntry.lineWidth =
        listEntry.curveStyle["mLineAttributesWrapper"].mLineWidth;
      style = listEntry.curveStyle["mLineAttributesWrapper"].mStyle;
      cap = listEntry.curveStyle["mLineAttributesWrapper"].mCap;
      listEntry.lineStyle = this.mapCodeToLineStyle(style, cap);
    } else {
      listEntry.lineWidth = listEntry.curveStyle["lineAttributes"].width;
      style = listEntry.curveStyle["lineAttributes"].style;
      listEntry.lineStyle = this.mapCodeToLineStyle(style, cap);
    }
    return listEntry;
  }
  async synchronizeCurveStyle(
    orderNumber: string,
    color: string,
    lineStyle: string,
    lineWidth: number,
    productPosition: number,
    projectId: string
  ): Promise<ListEntry> {
    let listEntry = new ListEntry();
    let url = await serviceUrlConstants.synchronizeCurveStyleUrl(
      projectId,
      productPosition
    );

    if (orderNumber) {
      await AxiosClient.post(
        url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": localStorage.i18nextLng,
          },
          timeout: 30000,
        },
        {
          hexColorCode: color,
          lineStyle: lineStyle,
          lineWidth: lineWidth,
        }
      )
        .then((response) => {
          switch (response.type) {
            case ResultType.SUCCESS: {
              // listEntry = response.value;
              listEntry = this.mapCurveStyle(response.value);
              return listEntry;
            }
            case ResultType.FAIL:
              throw response.message;
          }
        })
        .catch((error) => {
          throw error;
        });
      return listEntry;
    } else {
      let error = new AxiosError(Constants.INVALID_PRODUCTGROUP_ERROR);
      throw error;
    }
  }
  //TODO::Folder Id Removal Migration
  async deleteDevice(index: number, projectId: string): Promise<any> {
    let url = serviceUrlConstants.getDeleteDeviceDataUrl(index, projectId);
    await AxiosClient.delete(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage.i18nextLng,
      },
      timeout: 30000,
    })
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            return response.value;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  async updateDeviceLabel(
    listEntry: ListEntry,
    label: string,
    index: number,
    projectId: string
  ): Promise<ListEntry> {
    let listEntryResponse = new ListEntry();

    let url = serviceUrlConstants.getUpdateDeviceLabelUrl(projectId, index);
    let postBody = this.postBodyForUpdateDeviceLabel(listEntry, label, index);
    await AxiosClient.post(
      url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage.i18nextLng,
        },
        timeout: 30000,
      },
      postBody
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            listEntry = this.mapCurveStyle(response.value);
            return response;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return listEntry;
  }

  postBodyForUpdateDeviceLabel(
    listEntry: ListEntry,
    label: string,
    index: number
  ) {
    let body = {
      mProduct: listEntry.mProduct,
      mProtectiveDevice: listEntry.mProtectiveDevice,
      label: label,
      index: index,
      lineConfigModel: {
        hexColorCode: listEntry.color,
        lineStyle: listEntry.lineStyle,
        lineWidth: listEntry.lineWidth,
      },
    };
    return body;
  }
  //TODO::Folder Id Removal Migration
  async moveDevicePosition(
    projectId: string,
    currentIdx: number,
    updatedIdx: number,
    view: string
  ): Promise<ListEntryMarkModel> {
    let url = serviceUrlConstants.getmoveListItemUrl(
      projectId,
      currentIdx,
      updatedIdx,
      view
    );
    let listEntries = new Array<ListEntry>();
    let listEntryResponse = new Array<ListEntry>();
    let markenteries = new Array<MarkModel>();
    let listAndMarkEntry = new ListEntryMarkModel();
    await AxiosClient.post(
      url,

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage.i18nextLng,
        },
        timeout: 30000,
      },
      {}
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            markenteries = response.value[1];
            listEntries = response.value[0];
            for (let i = 0; i < markenteries.length; i++) {
              let mark = markenteries[i];
              mark.rgbValue = convertRgbToHex(mark.rgbValue);
              markenteries[i].mlabel = mark.rgbValue;
            }
            for (let index = 0; index < listEntries.length; index++) {
              let listEntry = listEntries[index];
              if (listEntry.curveStyle) {
                listEntry.color = convertRgbToHex(listEntry.curveStyle["rgb"]);
                listEntry.mProduct = this.mapProductAttributesToModel(
                  listEntry.mProduct
                );
                let style, mCap;
                if (listEntry.curveStyle["mLineAttributesWrapper"] != null) {
                  listEntry.lineWidth =
                    listEntry.curveStyle["mLineAttributesWrapper"].mLineWidth;
                  style = listEntry.curveStyle["mLineAttributesWrapper"].mStyle;
                  mCap = listEntry.curveStyle["mLineAttributesWrapper"].mCap;
                } else {
                  listEntry.lineWidth =
                    listEntry.curveStyle["lineAttributes"].width;
                  style = listEntry.curveStyle["lineAttributes"].style;
                }
                listEntry.lineStyle = this.mapCodeToLineStyle(style, mCap);
              }
              listEntryResponse.push(listEntry);
            }
            listAndMarkEntry.listEntry = listEntryResponse;
            listAndMarkEntry.markModel = markenteries;

            return listAndMarkEntry;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((error) => {
        throw error;
      });
    return listAndMarkEntry;
  }
}

export function convertRgbToHex(rgb: any): string {
  let hexColor = rgbToHex(rgb["red"], rgb["green"], rgb["blue"]);

  return hexColor;
}
export function componentToHex(c) {
  var hex = c?.toString(16);
  return hex?.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
