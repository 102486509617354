import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { Subscription } from "rxjs";
import { MarkViewType } from "../../models/constants/mark-view-type";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { TooltipDirective } from "../../common/tooltip.directive";
import { FormsModule } from "@angular/forms";
import { InputComponent } from "./../../../../packages/ui/src/primitives/input/input.component";

@Component({
    selector: "app-input-scl-mark",
    templateUrl: "./input-scl-mark.component.html",
    styleUrls: ["./input-scl-mark.component.css"],
    standalone: true,
    imports: [FormsModule, TooltipDirective,InputComponent],
})
export class InputSclMarkComponent implements OnChanges, OnDestroy {
  @Input() inputValue;
  @Input() inputLabel: string;
  @Input() tranlatedTooltipValue: string;
  @Input() isMarkSelected: boolean;
  @Input() isCurrent: boolean;
  @Input() view: MarkViewType;
  @Output() emitInputValue = new EventEmitter();

  subscriptions: Subscription = new Subscription();
  translatedInputValue: string;
  constructor(public eventBus: NgEventBus) {}
  ngOnChanges() {
    this.translatedInputValue = this.inputValue
      ? this.inputValue.toLocaleString(localStorage.getItem("i18nextLng"))
      : "";
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  onValueChange(value) {
    if (this.isMarkSelected) {
      value = serviceFactory.MarksHelper.convertLocaleNumber(
        value,
        localStorage.getItem("i18nextLng")
      );
      if (this.isCurrent) {
        if (serviceFactory.MarksHelper.isCurrentValueValid(value)) {
          this.emitInputValue.emit(value);
        } else {
          this.translatedInputValue = this.inputValue.toLocaleString(
            localStorage.getItem("i18nextLng")
          );
        }
      } else {
        if (serviceFactory.MarksHelper.isMarkTimeValid(value, this.view)) {
          this.emitInputValue.emit(value);
        } else {
          this.translatedInputValue = this.inputValue.toLocaleString(
            localStorage.getItem("i18nextLng")
          );
        }
      }
    } else {
      if (value) {
        value = serviceFactory.MarksHelper.convertLocaleNumber(
          value,
          localStorage.getItem("i18nextLng")
        );
        this.emitInputValue.emit(value);
      } else {
        this.translatedInputValue = this.inputValue.toLocaleString(
          localStorage.getItem("i18nextLng")
        );
      }
    }
  }
}
