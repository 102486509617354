export default class DriveFileResponse {
  fileName: string;
  description: string;
  uuid: string;
  fileType: string;
  fileSize: string;
  tags: any;
  createdDate: Date;
  lastUpdatedDate: Date;
  documentType: string;
  url: string;
  applicationId: string;
}
