import { CdkListbox, CdkOption } from "@angular/cdk/listbox";
import { Overlay, OverlayModule } from "@angular/cdk/overlay";
import { AsyncPipe, LowerCasePipe, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { AuthService, User } from "@auth0/auth0-angular";
import { TranslateModule } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import SpinnerModel from "../../models/common/SpinnerModel";
import { ProjectService } from "../../projects/services/project.service";
import DriveFileResponse from "../../services/file/DriveFileResponse";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { removeSessionCookie } from "../../../lib/utils";
import { DropdownComponent } from "../../../../packages/ui/src/primitives/dropdown/dropdown.component";
import { TooltipDirective } from "../../common/tooltip.directive";
import { IconsComponent } from "../../icons/icons.component";
import DialogModel from "../../models/common/DialogModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import { LogoutService } from "../../services/serviceLayer/logout.service";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { InformationPageComponent } from "../../shared/information-page/information-page.component";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  standalone: true,
  imports: [
    MatFormField,
    IconsComponent,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    TooltipDirective,
    AsyncPipe,
    LowerCasePipe,
    TranslateModule,
    OverlayModule,
    CdkListbox,
    CdkOption,
    DropdownComponent,
    InformationPageComponent,
  ],
})
export class HeaderComponent {
  localeList: Array<string> = [];
  selectedValue: string = "EN";
  dialogModel = new DialogModel();
  user$: any;
  langPreFixForTranslation = Constants.LANGUAGE_PREFIX;
  isOpen = false;
  isUserOverOpen = false;
  isInfoOverOpen = false;
  scrollStrategy = this.overlay.scrollStrategies.reposition();
  constructor(
    private dialog: MatDialog,
    public router: Router,
    public eventBus: NgEventBus,
    private authService: AuthService,
    private overlay: Overlay,
    private logoutService: LogoutService,
    public toasterService: ToasterService,
    public sessionExpiredDialog: SessionExpiredService,
    public projectService: ProjectService
  ) {
    this.user$ = this.authService.user$;
    this.localeList = serviceFactory.LocalizationService.getLocaleList();
    this.selectedValue = localStorage.getItem("i18nextLng");
  }

  changeLocaleLanguage(locale: Array<string>) {
    this.selectedValue = localStorage.getItem("i18nextLng");
    this.dialogModel.content = this.router.url.includes("/projects/")
      ? "Action_save_message"
      : "confirmation-dialog.confirmContent";
    this.dialogModel.header = "confirmation-dialog.header";
    this.dialogModel.actions = [
      {
        action: "confirmation-dialog.discard",
        type: "secondary",
      },
      {
        action: "confirmation-dialog.proceed",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.selectedValue = locale[0];
        localStorage.setItem("i18nextLng", locale[0].toLowerCase());
        // Save the project before reloading the page
        if (this.router.url.includes("/projects/")) {
          await this.saveProjectConfiguration().then((driveFileResponse) => {
            if (driveFileResponse) window.location.reload();
          });
        } else {
          window.location.reload();
        }
      } else {
        this.selectedValue = localStorage.getItem("i18nextLng");
      }
    });
  }

  async onLogout() {
    //FIXME: Do we need to call BE logout API to end the session?
    if (this.router.url.includes("/projects/")) {
      this.openSaveConfigConfirmationDialog();
    } else {
      this.authService.logout({
        openUrl: () => {
          this.router.navigate(["/login"], {
            queryParams: { isNotAuthenticated: true },
          });
        },
      });
    }
  }
  openSaveConfigConfirmationDialog() {
    let dialogHeight = "13rem";
    let dialogWidth = "37.5rem";
    let dialogModel = new DialogModel();
    dialogModel.content = "Action_save_message";
    dialogModel.header = "confirmation-dialog.header";
    dialogModel.actions = [
      {
        action: "confirmation-dialog.discard",
        type: "secondary",
      },
      {
        action: "confirmation-dialog.proceed",
        type: "primary",
      },
    ];
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogModel,
      height: dialogHeight,
      width: dialogWidth,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.saveProjectConfiguration().then((driveFileResponse) => {
          if (driveFileResponse) this.logoutService.logout();
        });
      } else if (result === false) {
        this.logoutService.logout();
      }
    });
  }
  async saveProjectConfiguration(): Promise<DriveFileResponse> {
    let spinnerModel = new SpinnerModel();
    let driveFileResponse = new DriveFileResponse();

    spinnerModel.show = true;
    spinnerModel.text = "loader.saveConfiguration";
    this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, spinnerModel);

    await serviceFactory.ProjectService.saveConfiguration(
      this.projectService.projectDetails().id
    )
      .then(async (fileResponse) => {
        removeSessionCookie("JSESSIONID");

        driveFileResponse = fileResponse;
        return driveFileResponse;
      })
      .catch((error: AxiosError) => {
        if (error.response?.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error?.message);
        }
      })
      .finally(async () => {
        spinnerModel.show = false;
        this.eventBus.cast(EventType.SHOW_HIDE_SPINNER, spinnerModel);
      });
    return driveFileResponse;
  }
  getUserInitials(user: User) {
    const { given_name, family_name } = user;
    const initials = `${given_name?.charAt(0).toUpperCase()}${family_name?.charAt(0).toUpperCase()}`;

    return initials;
  }
}
